import { Button, ButtonProps, CircularProgress } from "@mui/material";

import { colors } from "@theme/Themes";
import { styled } from "@mui/system";

interface BtnProps extends ButtonProps {
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

export const Btn = styled(Button)<BtnProps>`
  box-shadow: none;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:not(.expressCheckoutBtn, .gpay-button-loader-container) {
    font-weight: 600;
  }

  &.MuiButton-sizeLarge {
    line-height: 1;
    padding: 16px 10px;
  }

  &.MuiButton-sizeSmall {
    padding: 4px 8px;
  }

  .MuiTypography-root {
    text-transform: initial;
  }

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    color: ${({ color, variant }) =>
      variant === "contained" ? colors.paper : color};
  }

  &.back-btn {
    opacity: 0.4;
    padding: 6.1px 9px;
    &:hover {
      background-color: transparent;
      opacity: 1;
    }
  }

  &.login-btn {
    border-radius: 4px;
    border-color: ${colors.secondary};
    &:hover {
      background-color: ${({ color }) =>
        color === "secondary" && colors.secondary};
      color: ${colors.paper};
    }
  }

  /* &.gpay-button-loader-container {
    font-size: 1.5rem;
    font-weight: 500;
    height: 44px;
  } */

  .apple-pay-button-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const Loader = styled(CircularProgress)`
  left: 11px;
  position: absolute;
`;
