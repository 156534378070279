import braintree, { Client } from "braintree-web";
import { useCallback, useEffect, useState } from "react";

import AppleIcon from "@mui/icons-material/Apple";
import Button from "components/Button/Button";
import { ErrorType } from "components/PaymentDetails/PaymentDetails";
import { applePayLabels } from "@vendor/utils/constants";
import checkoutProcessing from "utils/checkoutProcessing";
import { colors } from "@theme/Themes";
import getCheckoutAmountRequestData from "@utils/getCheckoutAmountRequestData";
import styled from "styled-components";
import trackGaEvent from "utils/trackGaEvent";
import { useCheck } from "context/CheckContext";
import { useNavigateToCompletePage } from "@hooks/useNavigateToCompletePage";
import { usePlace } from "context/PlaceContext";

interface ApplePayProps {
  clientInstance: Client | undefined;
  amount: string;
  onError: (error: React.ReactNode) => void;
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>;
}

const ApplePayBtn = styled.div`
  &.apple-pay-button-with-text {
    border-radius: 6px;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: pay;
    width: 100%;
    height: 44px;
    -apple-pay-button-style: ${colors.applePayBtnStyle};
  }
  &.apple-pay-button-with-text > * {
    display: none;
  }
`;

function ApplePay({
  clientInstance: client,
  amount,
  onError,
  setError,
}: ApplePayProps) {
  const [isProcessingCheckout, setIsProcessingCheckout] =
    useState<boolean>(false);
  const { check, selectedTip, splitCheck } = useCheck();
  const { place } = usePlace();
  const { toCompletePage } = useNavigateToCompletePage();

  const [applePayInstance, setApplePayInstance] =
    useState<braintree.ApplePay>();
  const [dataCollector, setDataCollector] = useState<braintree.DataCollector>();

  const checkoutAmountRequest = getCheckoutAmountRequestData(
    check,
    splitCheck,
    selectedTip
  );

  const setCheckoutErrorState = useCallback(
    (_error: any, session?: ApplePaySession) => {
      if (_error.code === "APPLE_PAY_NOT_ENABLED") {
        setError("ApplePayNotEnabled");

        trackGaEvent("Error: ApplePay Not Enabled", {
          description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
        });

        session?.completePayment(ApplePaySession.STATUS_FAILURE);

        return;
      }

      setError("checkoutError");
      setIsProcessingCheckout(false);

      onError(
        <>
          <strong>Error:</strong> There was an error processing your payment.
          Please try again or contact your server.
        </>
      );

      trackGaEvent("Error: ApplePay performCheckout", {
        description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
      });

      session?.completePayment(ApplePaySession.STATUS_FAILURE);
    },
    [check?.number, onError, place?.code, setError]
  );

  const createApplePay = useCallback(async () => {
    if (client) {
      await braintree.applePay
        .create({
          client,
        })
        .then((applePayInstance) => {
          setApplePayInstance(applePayInstance);
        })
        .catch((error) => {
          console.error(
            `ApplePay > Error Creating Braintree Payment instance: ${error.code}`,
            error
          );
          setCheckoutErrorState(error);
        });
    }
  }, [client, setCheckoutErrorState]);

  const createDataCollector = useCallback(async () => {
    if (client) {
      await braintree.dataCollector
        .create({ client })
        .then((dataCollectorInstance) => {
          setDataCollector(dataCollectorInstance);
        })
        .catch((error) =>
          console.error(
            "ApplePay > Error creating Data Collector instance",
            error
          )
        );
    }
  }, [client]);

  useEffect(() => {
    if (client) {
      Promise.all([createApplePay(), createDataCollector()]).catch((error) =>
        console.error(error)
      );
    }
  }, [client, createApplePay, createDataCollector]);

  const braintreePaymentRequest = applePayInstance?.createPaymentRequest({
    total: {
      label: applePayLabels.paymentSheet.totalLabel,
      amount,
    },
    requiredBillingContactFields: ["name", "postalAddress"],
  });

  const performCheckout = useCallback(
    async (session: ApplePaySession, applePayNonce: string) => {
      if (!place?.code || !check?.id || !check?.number) {
        return;
      }

      const response = await checkoutProcessing({
        paymentMethod: "APPLE_PAY",
        placeCode: place?.code,
        ticketId: check?.id,
        tipInCents: checkoutAmountRequest.tipInCents,
        amexPointsInDollarAmount: 0.0,
        nonce: applePayNonce,
        deviceData: dataCollector?.deviceData,
        customAmountInCents: checkoutAmountRequest.customAmountInCents,
        ticketNumber: check?.number,
        splitCheckoutType: splitCheck?.splitCheckoutType,
      });

      if (response?.charged) {
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        toCompletePage(checkoutAmountRequest, response);
      } else {
        setCheckoutErrorState(session);
      }
    },
    [
      place?.code,
      check?.id,
      check?.number,
      checkoutAmountRequest,
      dataCollector?.deviceData,
      splitCheck?.splitCheckoutType,
      toCompletePage,
      setCheckoutErrorState,
    ]
  );

  const handleApplePay = useCallback(() => {
    if (braintreePaymentRequest) {
      setError(undefined);
      setIsProcessingCheckout(true);

      const session = new ApplePaySession(3, braintreePaymentRequest as any);

      session.oncancel = (event: ApplePayJS.Event): void => {
        event.cancelBubble = true;
        setIsProcessingCheckout(false);

        trackGaEvent("ApplePay onCancel by user", {
          description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
        });
      };

      session.onpaymentauthorized = (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        if (event.payment) {
          applePayInstance
            ?.tokenize({ token: event.payment.token })
            .then((payload) => {
              performCheckout(session, payload.nonce)
                .then((_) => "Checkout Success")
                .catch((error) => {
                  setIsProcessingCheckout(false);
                  console.error("ApplePay > Checkout failed", error);
                  setCheckoutErrorState(session);
                });
            })
            .catch((error) => {
              console.error("ApplePay > onpaymentauthorized >", error);
              setIsProcessingCheckout(false);
              setCheckoutErrorState(session);

              trackGaEvent("Error: ApplePay tokenize", {
                description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
              });
            });
        }
      };

      session.onvalidatemerchant = (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        if (event.validationURL) {
          applePayInstance
            ?.performValidation({
              validationURL: event.validationURL,
              displayName: applePayLabels.validation.displayName,
            })
            .then((merchantSession) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch((error) => {
              console.error("ApplePay > Error Validating Merchant", error);
              setIsProcessingCheckout(false);
              setCheckoutErrorState(session);

              trackGaEvent("Error: Validating ApplePay Merchant", {
                description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
              });

              try {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
              } catch (error) {
                console.error(
                  "ApplePay > Unable to set failure status for session",
                  session,
                  error
                );
              }
              session.abort();
            });
        } else {
          console.error("ApplePay > Missing Merchant Validation URL", event);
          setIsProcessingCheckout(false);

          trackGaEvent("Error: Missing Apple Pay Merchant Validation URL", {
            description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
          });
        }
      };

      session.begin();
    }

    trackGaEvent("Clicked ApplePay Button", {
      description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
    });
  }, [
    applePayInstance,
    braintreePaymentRequest,
    check?.number,
    performCheckout,
    place?.code,
    setCheckoutErrorState,
    setError,
  ]);

  if (!applePayInstance) {
    return <></>;
  }

  if (isProcessingCheckout) {
    return (
      <Button
        ariaLabel="Processing payment with Apple Pay"
        className="expressCheckoutBtn"
        isLoading={isProcessingCheckout}
        size="medium"
      />
    );
  }

  return (
    <ApplePayBtn
      className="apple-pay-button-with-text"
      onClick={handleApplePay}
    />
  );
}

export default ApplePay;
